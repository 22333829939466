<template>
  <b-container

    fluid
  >
    <b-card :key="componentKey">
      <b-row class="px-1 mb-1 justify-content-between">
        <b-button
          variant="primary"
          @click="() => $refs.modal.show()"
        >
          Cadastrar Item
        </b-button>

        <b-button
          variant="primary"
          @click="save"
        >
          Salvar
        </b-button>
      </b-row>
      <b-table
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        class="text-center"
        :fields="[
          { key: 'document', label: 'Documento' },
          { key: 'name', label: 'Nome' },
          { key: 'type', label: 'Tipo' },
          { key:'actions', label: 'Ações'}
        ]"
        :items="items"
      >
        <template #cell(type)="data">
          {{ translateType(data.item.type) }}
        </template>

        <template #cell(actions)="data">
          <b-button
            :variant="data.item.erased ? 'primary' : 'danger'"
            @click="erase(data.item)"
          >
            {{ data.item.erased ? 'Retomar' : 'Apagar' }}
          </b-button>
        </template>

        <template #cell(document)="data">
          {{ data.item.document | tax_id }}
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>

    <b-modal
      ref="modal"
      size="lg"
      hide-footer
    >
      <b-form-group
        label="Empresa"
        label-for="company"
      >
        <b-form-select
          v-model="company"
          :options="companyOptions"
        />
      </b-form-group>

      <b-form-group
        label="Tipo"
        label-for="type"
      >
        <b-form-select
          v-model="type"
          :options="typeOptions"
          :disabled="!company"
        />
      </b-form-group>

      <b-button
        variant="primary"
        :disabled="!company || !type"
        @click="() => addInBlackList()"
      >
        Adicionar
      </b-button>
    </b-modal>

  </b-container>
</template>

<script>
import {
    BContainer, BTable, BCard, BPagination, BButton, BModal, BFormSelect, BFormGroup, BRow,
} from 'bootstrap-vue';

import BiService from '@/service/bi/index';

const typeEnum = {
    Assignor: 'Cedente',
    Soulpay: 'Meios de Pagamento',
};

const translatorType = type => typeEnum[type];

export default {
    components: {
        BContainer, BTable, BCard, BPagination, BButton, BModal, BFormSelect, BFormGroup, BRow,
    },

    data() {
        return {
            tableData: [],
            newItems: [],
            deletedItems: [],
            company: null,
            type: null,
            customers: [],
            currentPage: 1,
            perPage: 10,
            totalRows: 1,
            componentKey: 1,
        };
    },

    computed: {
        companyOptions() {
            const options = this.customers.map(item => ({
                value: item.document,
                text: `${item.name} - ${item.document}`,
            }));

            return options;
        },

        typeOptions() {
            const filtered = this.tableData.filter(item => item.document === this.company).map(item => item.type);

            return Object.entries(typeEnum).filter(([key]) => !filtered.includes(key) && this.customers.find(item => item.document === this.company)?.types.includes(key)).map(([key, item]) => ({
                value: key,
                text: item,
            }));
        },

        items() {
            return this.tableData.sort((a, b) => a.name?.localeCompare(b.name));
        },
    },

    mounted() {
        this.onMounted();
    },

    methods: {
        async onMounted() {
            const customers = (await BiService.getAllCustomers()).data.data;
            const list = (await BiService.getList()).data.data;

            const listWithCustomers = list.map(item => (
                {
                    ...item,
                    name: customers.find(item2 => item2.document === item.document)?.name.trim(),
                }
            ));

            this.tableData = listWithCustomers.sort((a, b) => a.name?.localeCompare(b.name));
            this.totalRows = listWithCustomers.length;
            this.customers = customers.sort((a, b) => a.name?.localeCompare(b.name));
        },
        translateType(type) {
            return translatorType(type);
        },

        async save() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente salvar a BlackList?');

            if (!isConfirmed) return;
            this.callLoading(true);
            await BiService.deleteInBlacklist(this.tableData.filter(item => item.erased).map(item => item.id));
            const { status } = await BiService.addInBlacklist(Object.values(this.newItems.reduce((acc, item) => {
                const itemToInsert = acc[item.document];

                if (itemToInsert) {
                    itemToInsert.type.push(item.type);
                    return acc;
                }

                acc[item.document] = {
                    ...item,
                    type: [item.type],
                };

                return acc;
            }, {})));
            this.callLoading(false);

            if (status === 200) {
                this.modalSuccess('Black List salva com sucesso');
            } else {
                this.modalError('Erro ao salvar blacklist');
            }

            this.onMounted();
        },

        addInBlackList() {
            const item = {
                dashboard_id: 5,
                document: this.company,
                type: this.type,
                name: this.customers.find(item => item.document === this.company)?.name.trim(),
            };
            this.tableData.push(item);
            this.newItems.push(item);

            this.company = null;

            this.$refs.modal.hide();
        },

        erase(item) {
            const founded = this.tableData.find(item2 => item2.id === item.id);

            if (!founded) {
                this.tableData.splice(this.tableData.findIndex(item2 => item2.document === item.document && item2.type === item.type), 1);
                this.componentKey++;
                return;
            }

            if (founded.erased) {
                founded.erased = false;
                this.componentKey++;
                return;
            }

            founded.erased = true;
            this.componentKey++;
        },
    },
};

</script>
